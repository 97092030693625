import React from 'react';
import './startScreen.css';

export default function StartScreen(props) {

    const handleClick = async() => {
        props.setPopOpen(false);
    }

  return (
    <div className="practice-test">
      <h1 className="practice-test__title">Practice Test 1</h1>
      <p className="practice-test__subtitle">25 Questions</p>
      
      <div className="practice-test__info">
        <div className="practice-test__info-item">
          <img src="/assets/icons/timing.png" alt="" className="practice-test__icon"/>
          <div className="practice-test__info-content">
            <h2 className="practice-test__info-title">Timing</h2>
            <p className="practice-test__info-text">Practice tests are timed, but you can pause them. To continue on another device, you have to start over. We delete incomplete practice tests after 90 days.</p>
          </div>
        </div>
        
        <div className="practice-test__info-item">
          <img src="/assets/icons/scores.png" alt="" className="practice-test__icon"/>
          <div className="practice-test__info-content">
            <h2 className="practice-test__info-title">Scores</h2>
            <p className="practice-test__info-text">When you finish the practice test, go to <strong>My Practice</strong> to see your score and get personalized study tips.</p>
          </div>
        </div>
        
        <div className="practice-test__info-item">
          <img src="/assets/icons/at.png" alt="" className="practice-test__icon"/>
          <div className="practice-test__info-content">
            <h2 className="practice-test__info-title">Assistive Technology</h2>
            <p className="practice-test__info-text">If you use assistive technology, try it out on the practice test so you know what to expect on test day.</p>
          </div>
        </div>
        
        <div className="practice-test__info-item">
          <img src="/assets/icons/ndt.png" alt="" className="practice-test__icon"/>
          <div className="practice-test__info-content">
            <h2 className="practice-test__info-title">No Device Lock</h2>
            <p className="practice-test__info-text">We don’t lock your device during practice. On test day, you’ll be blocked from accessing other programs or apps.</p>
          </div>
        </div>
      </div>

      <button className="practice-test__start-button" onClick={handleClick}>Start</button>
    </div>
  );
};

