import React, { useState, useEffect } from "react"
import "./practiceTest.css"
import { useNavigate } from "react-router-dom";
import { format } from 'date-fns';
import { backend } from "../../App";

export default function PracticeTest(props) {

    const suspended = JSON.parse(localStorage.getItem("index"));
    let token = sessionStorage.getItem("token");
    const navigate = useNavigate();
    const [chooseM, setChooseM] = useState('0');
    const [chooseV, setChooseV] = useState('0');
    const [chooseB, setChooseB] = useState('5');
    const [chooseSection, setChooseSection] = useState(suspended && suspended.sat_id === props.test.sat_test_id ? (suspended.sectionKey === "first" ? suspended.section : suspended.section === 'math' ? "verbal" : "math") : 'math');
    const [timeOpen, setTimeOpen] = useState('');
    const [timeEnd, setTimeEnd] = useState('');
    const [popOpen, setPopOpen] = useState(false);
    const [popOpen2, setPopOpen2] = useState(false);
    const [deadlines, setDeadlines] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [mode, setMode] = useState('');
    const [deadlineId, setDeadlineId] = useState(-1);

    const [selectedGroup, setSelectedGroup] = useState('');
    const [groups, setGroups] = useState([]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, 'HH:mm aa dd MMMM');
    };

    useEffect(() => {
        (
            async() => {
                if(sessionStorage.getItem('role') !== 'student'){
                    const response = await fetch(`${backend}/api/groups`, {
                        headers: { 'Authorization': 'Bearer ' + token }
                    });
                    if (response.ok) {
                        const data = await response.json();
                        setGroups(data);
                        if (data.length > 0) {
                          setSelectedGroup(data[0].group_id); // Set default selected group
                        }
                    }
                    const res = await fetch(`${backend}/api/satTests/${props.test.sat_test_id}/deadlines`, {
                        headers: { 'Authorization': 'Bearer ' + token }
                    });
                    if (res.ok) {
                        const data = await res.json();
                        setDeadlines(data);
                    }
                }
            }
        )()
    }, [backend]);

    useEffect(() => {
        (
            async() => {
                if(sessionStorage.getItem('role') !== 'student' && refresh === true){
                    const res = await fetch(`${backend}/api/satTests/${props.test.sat_test_id}/deadlines`, {
                        headers: { 'Authorization': 'Bearer ' + token }
                    });
                    if (res.ok) {
                        const data = await res.json();
                        setDeadlines(data);
                    }
                }
                setRefresh(false);
            }
        )()
    }, [refresh]);

    const handleDelete = async() => {
        await fetch(`${backend}/api/satTests/${props.test.sat_test_id}`, {
            method: "DELETE",
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        }).then((response) => {
            if (response && response.status === 200) {
                response.json().then((data) => {
                    props.setRefresh(true);
                });
            }
        });
    }

    const handleUpdate = async(way,id) => {
        if(way === 'add'){
            await fetch(`${backend}/api/satTests/deadlines`, {
                method: "POST",
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
                body: JSON.stringify({
                    test_id: props.test.sat_test_id,
                    group_id: selectedGroup,
                    open: timeOpen,
                    due: timeEnd
                })
            }).then((response) => {
                if (response && response.status === 200) {
                    response.json().then((data) => {
                        props.setRefresh(true);
                        setRefresh(true);
                    });
                }
            });
            await fetch(`${backend}/api/calendars`, {
                method: "POST",
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
                body: JSON.stringify({
                    title: props.test.name,
                    description: 'demo SAT',
                    group_id: selectedGroup,
                    startTime: timeOpen,
                    endTime: timeEnd
                })
            }).then((response) => {
                if (response && response.status === 201) {
                } else {
                    console.log("Error: " + response.status);
                }
            });
        }
        if(way === 'update'){
            await fetch(`${backend}/api/deadlines/${id}`, {
                method: "PUT",
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
                body: JSON.stringify({
                    open: timeOpen,
                    due: timeEnd
                })
            }).then((response) => {
                if (response && response.status === 200) {
                    response.json().then((data) => {
                        props.setRefresh(true);
                        setRefresh(true);
                    });
                }
            });
            await fetch(`${backend}/api/calendars`, {
                method: "POST",
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
                body: JSON.stringify({
                    title: props.test.name,
                    description: 'demo SAT',
                    group_id: selectedGroup,
                    startTime: timeOpen,
                    endTime: timeEnd
                })
            }).then((response) => {
                if (response && response.status === 201) {
                } else {
                    console.log("Error: " + response.status);
                }
            });
        }
        setTimeEnd('');
        setTimeOpen('');
        setMode('');
        setDeadlineId(-1);
        setPopOpen(false);
    }

    const handleEdit = async(way, deadline) => {
        if(way === 'add'){
            setMode('add');
            for(let i = 0 ; i < groups.length; i++){
                if(!deadlines.find((dead) => dead.group_id === groups[i].group_id)){
                    setSelectedGroup(groups[i].group_id);
                    break;
                }
            }
            setPopOpen(true);
        }
        if(way === 'update')
        {
            setMode('update');
            setDeadlineId(deadline.deadline_id);
            setSelectedGroup(deadline.group_id);
            setPopOpen(true);
        }
        if(way === 'delete')
        {
            await fetch(`${backend}/api/deadlines/${deadline.deadline_id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((res) => {
                if(res.status === 200){
                    setRefresh(true);
                }
            })
        }
    }

    const popup = (
        <div className="practiceTest-popUp">
            <div className="practiceTest-popUp-close" onClick={() => setPopOpen(false)}></div>
            <div className="practiceTest-popUp-block">
                <div className="practiceTest-popUp-ramka">
                    <div>
                        <label>Group</label>
                        {
                            mode === 'add' ?
                            <select 
                                value={selectedGroup}
                                onChange={(e) => setSelectedGroup(e.target.value)}
                                >
                                {groups[0] && groups.map(group => {
                                    if(!deadlines.find((dead) => dead.group_id === group.group_id)){
                                        return(
                                            <option key={group.group_id} value={group.group_id}>
                                            {group.name}
                                            </option>
                                        )
                                    } 
                                })}
                            </select>
                            :
                            mode === 'update' &&
                            <select 
                                value={selectedGroup}
                                onChange={(e) => setSelectedGroup(e.target.value)}
                                disabled
                                >
                                {groups[0] && groups.map(group => (
                                    <option key={group.group_id} value={group.group_id}>
                                        {group.name}
                                    </option>
                                ))}
                            </select>
                        }
                    </div>
                    <div>
                        <label>Time Open</label>
                        <input
                            type="datetime-local"
                            value={timeOpen}
                            onChange={(e) => setTimeOpen(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Due Time</label>
                        <input
                            type="datetime-local"
                            value={timeEnd}
                            onChange={(e) => setTimeEnd(e.target.value)}
                            required
                        />
                    </div>
                    <button onClick={() => mode === 'update' ? handleUpdate('update', deadlineId) : mode === 'add' && handleUpdate('add')}>{mode === 'update' ? 'Update' : 'Add'}</button>
                </div>
            </div>
        </div>
    )

    const popup2 = (
        <div className="practiceTest-popUp2">
            <div className="practiceTest-popUp2-close" onClick={() => setPopOpen2(false)}></div>
            <div className="practiceTest-popUp2-block">
                <div className="practiceTest-popUp2-ramka">
                    {
                        deadlines.sort((a,b) => a.deadline_id - b.deadline_id).map((dead) => (
                            <div key={dead.deadline_id} className="practiceTest-popUp2-wrapper">
                                <label>{groups.find((gr) => gr.group_id === dead.group_id) && groups.find((gr) => gr.group_id === dead.group_id).name}</label>
                                <div className="practiceTest-popUp2-date">
                                    <div>Open: {formatDate(dead.open)}</div>
                                    <div>Due: {formatDate(dead.due)}</div>
                                </div>
                                <button onClick={() => handleEdit('update', dead)}>Edit</button>
                                <button onClick={() => handleEdit('delete', dead)} className="practiceTest-popUp2-delete-button">Delete</button>
                            </div>
                        ))
                    }
                    <button className="practiceTest-popUp2-add-button" onClick={() => handleEdit('add')}>Add new Deadline</button>
                </div>
            </div>
        </div>
    )

    return(
        <>
            {popOpen && popup}
            {popOpen2 && popup2}
            <div className={sessionStorage.getItem('role') !== 'student' ? "practiceTest-edit" : "practiceTest"}>
                <div className="practiceTest-title">{props.test.name}</div>
                {
                    sessionStorage.getItem('role') !== 'student' ? 
                    <>
                        <div className="practiceTest-date-wrapper-admin">
                            <button onClick={() => setPopOpen2(true)}>Group Deadlines</button>
                        </div>
                    </>
                    :
                    <>
                        <div className="practiceTest-extendTime-item">
                            <div className="practiceTest-extendTime-item-title">Choose first Section</div>
                            <div className="practiceTest-extendTime-item-list">
                                <div className={chooseSection === 'math' ? "practiceTest-extendTime-item-choose active" : "practiceTest-extendTime-item-choose"} onClick={() => suspended && suspended.sat_id === props.test.sat_test_id ? null : setChooseSection('math')}>Math</div>
                                <div className={chooseSection === 'verbal' ? "practiceTest-extendTime-item-choose active" : "practiceTest-extendTime-item-choose"} onClick={() => suspended && suspended.sat_id === props.test.sat_test_id ? null : setChooseSection('verbal')}>Verbal</div>
                            </div>
                        </div>
                        <div className="practiceTest-extendTime-item">
                            <div className="practiceTest-extendTime-item-title">Extend time on Math</div>
                            <div className="practiceTest-extendTime-item-list">
                                <div className={chooseM === '5' ? "practiceTest-extendTime-item-choose active" : "practiceTest-extendTime-item-choose"} onClick={() => chooseM === '5' ? setChooseM('0') : setChooseM('5')}>5 MIN</div>
                                <div className={chooseM === '10' ? "practiceTest-extendTime-item-choose active" : "practiceTest-extendTime-item-choose"} onClick={() => chooseM === '10' ? setChooseM('0') : setChooseM('10')}>10 MIN</div>
                                <div className={chooseM === '15' ? "practiceTest-extendTime-item-choose active" : "practiceTest-extendTime-item-choose"} onClick={() => chooseM === '15' ? setChooseM('0') : setChooseM('15')}>15 MIN</div>
                            </div>
                        </div>
                        <div className="practiceTest-extendTime-item">
                            <div className="practiceTest-extendTime-item-title">Extend time on Verbal</div>
                            <div className="practiceTest-extendTime-item-list">
                                <div className={chooseV === '5' ? "practiceTest-extendTime-item-choose active" : "practiceTest-extendTime-item-choose"} onClick={() => chooseV === '5' ? setChooseV('0') : setChooseV('5')}>5 MIN</div>
                                <div className={chooseV === '10' ? "practiceTest-extendTime-item-choose active" : "practiceTest-extendTime-item-choose"} onClick={() => chooseV === '10' ? setChooseV('0') : setChooseV('10')}>10 MIN</div>
                                <div className={chooseV === '15' ? "practiceTest-extendTime-item-choose active" : "practiceTest-extendTime-item-choose"} onClick={() => chooseV === '15' ? setChooseV('0') : setChooseV('15')}>15 MIN</div>
                            </div>
                        </div>
                        <div className="practiceTest-extendTime-item">
                            <div className="practiceTest-extendTime-item-title">Extend time on Break</div>
                            <div className="practiceTest-extendTime-item-list">
                                <div className={chooseB === '5' ? "practiceTest-extendTime-item-choose active" : "practiceTest-extendTime-item-choose"} onClick={() => setChooseB('5')}>5 MIN</div>
                                <div className={chooseB === '10' ? "practiceTest-extendTime-item-choose active" : "practiceTest-extendTime-item-choose"} onClick={() => setChooseB('10')}>10 MIN</div>
                                <div className={chooseB === '15' ? "practiceTest-extendTime-item-choose active" : "practiceTest-extendTime-item-choose"} onClick={() => setChooseB('15')}>15 MIN</div>
                            </div>
                        </div>
                        <div className="practiceTest-date-wrapper">
                            <div className="practiceTest-date">
                                <div>Opens</div>
                                <div>{formatDate(props.test.opens)}</div>
                            </div>
                            <div className="practiceTest-date">
                                <div>Due</div>
                                <div>{formatDate(props.test.due)}</div>
                            </div>
                        </div>
                    </>
                }
                {
                    sessionStorage.getItem('role') === 'student' ? 
                    <button className="practiceTest-button" onClick={() => navigate('/sat-practice', {state: {sectionType: chooseSection, nextSection: chooseSection === 'math' ? 'verbal' : 'math' , section: 'first', breakTime: chooseB, duration: chooseSection === 'math' ? chooseM : chooseV , duration2: chooseSection === 'math' ? chooseV : chooseM , sat_id: props.test.sat_test_id}})}>{suspended && suspended.sat_id === props.test.sat_test_id ? "Continue" : "Start"}</button>
                    :
                    <div className="practiceTest-button-admin">
                        <button onClick={() => navigate('/sat-practice', {state: {sectionType: chooseSection, nextSection: chooseSection === 'math' ? 'verbal' : 'math' , section: 'first', breakTime: chooseB, duration: chooseSection === 'math' ? chooseM : chooseV , duration2: chooseSection === 'math' ? chooseV : chooseM , sat_id: props.test.sat_test_id}})}>Edit Test</button>
                        <button onClick={handleDelete}>Delete</button>
                    </div>
                }
            </div>
        </>
    )
}