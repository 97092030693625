import "./login.css"
import { useState, useEffect } from "react";
import { backend } from "../../App";
import { useNavigate } from "react-router-dom";

export default function Login(props) {
    
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailEmpty, setEmailEmpty] = useState(true);
    const [passwordEmpty, setPasswordEmpty] = useState(true);
    const [accExist, setAccExist] = useState(true);
    const navigate = useNavigate();

    const handleKeywordKeyPress = (e) => {
        if( e.key === 'Enter' ) login(e);
    };

    useEffect(() => {
        (
        async () => {
            setEmail(email.trim());
            setPassword(password.trim())
        })();
    }, [email, password])

    const login = async(e) => {
        e.preventDefault();
        setAccExist(true)
        setEmailEmpty(true);
        setPasswordEmpty(true);
        if(email.length === 0 || password.length === 0) {
            if(email.length === 0) setEmailEmpty(false);
            if(password.length === 0) setPasswordEmpty(false);
            return;
        }

        await fetch(`${backend}/api/auth/signin`, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            // credentials: 'include',
            body: JSON.stringify({
                email: email,
                password: password
            })
        }).then((response) => { 
            if (response && response.status === 200)
            {
                response.json().then((data) => {
                    window.sessionStorage.setItem("token", `${data.token}`);
                    window.sessionStorage.setItem("user_id", `${data.user_id}`);
                    window.sessionStorage.setItem("role", `${data.role}`);
                    window.sessionStorage.setItem("name", `${data.name}`);
                    fetch(`${backend}/api/user/${data.user_id}`, {
                        method: "GET",
                        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + data.token },
                        // credentials: 'include',
                    }).then((resp) => { 
                        resp.json().then((dat) => {
                            window.sessionStorage.setItem("group_id", `${dat.group_id}`);   
                            navigate("/");
                            window.location.reload();
                        })
                    })
                })
            }
            if(response.status === 400)
            {
                setAccExist(false);
            }
        })

        // await fetch( `${backend}/api/refresh`, {
        //     headers: { 'Content-Type': 'apppcation/json' },
        //     //credentials: 'include'
        // }).then((res) => {
        //     if (res && res.status === 200) {
        //         alert('darova')
        //     } else {
        //         alert('не дарова')
        //     }
        // })
    }


    return(
        <div className="log-page">
            <div className="log-ramka">
                <div className="log-content">
                    <div className="log-title">Sign in</div>
                    <div className="log-email">
                        <div className={emailEmpty ? "log-bar-text" : "log-bar-text log-empty"}>Email Address</div>
                        <input type="text" onKeyUp={handleKeywordKeyPress} className={emailEmpty ? "log-Bar" : "log-Bar log-incorrectBar"} onChange={(e) => setEmail(e.target.value)} value={email}/>
                    </div>
                    <div className="log-password">
                        <div className={passwordEmpty ? "log-bar-text" : "log-bar-text log-empty"}>Password</div>
                        <input type="password" onKeyUp={handleKeywordKeyPress} className={passwordEmpty ? "log-Bar" : "log-Bar log-incorrectBar"} onChange={(e) => setPassword(e.target.value)}/>
                    </div>
                    <button className="log-button" onClick={login}>Continue</button>
                    {/* <div className="log-password-recover"><a href="">Забыли пароль?</a></div> */}
                    {(!accExist) && <div className="log-email-incorrect">Incorrect email or password</div>}
                </div>
            </div>
        </div>
    );
}