import "./SATtest.css"
import { useState, useEffect } from "react";
import { MDXEditor, headingsPlugin, listsPlugin, quotePlugin, thematicBreakPlugin, imagePlugin } from '@mdxeditor/editor'
import { backend } from "../../../App";

const alphabet = ["A", "B", "C", "D", "E","F","G","H","I","J","K","L","M","N","O","P"];

export default function SATtest(props) {
    const [chooseQ, setChooseQ] = useState(-1);
    const [editorKey, setEditorKey] = useState(0);
    const [bMode, setBMode] = useState(-1);
    const [newOptionText, setNewOptionText] = useState('');
    const [check, setCheck] = useState(false);

    // Update the key whenever activeIndex changes
    useEffect(() => {
        setEditorKey(prevKey => prevKey + 1);
    }, [props.question]);

    const handleAnswer = async(option) => {
        if(sessionStorage.getItem("role") === 'student'){
            setChooseQ(option);
            props.setOption(option);
        }
    }

    const handleCross = async(index) => {
        if(props.crossout.includes(index)){
            let n = props.crossout.findIndex((val) => val === index);
            let newArr = props.crossout.slice(0, n).concat(props.crossout.slice(n+1))
            props.setCrossout(newArr)
            return;
        }
        props.crossout.push(index)
        return;
    }

    const handleUpdateOption = async(option) => {
        if(bMode === -1){
            setBMode(option.sat_answer_option_id)
            setNewOptionText(option.text)
            await fetch(`${backend}/api/satAnswerOptions/${option.sat_answer_option_id}`, {
                method: "GET",
                headers: { 'Authorization': 'Bearer ' + props.token },
            }).then((response) => {
                if (response && response.status === 200) {
                    response.json().then((data) => {
                        setCheck(data.is_correct)
                    });
                } else {
                    console.log(response.status)
                }
            });
        }
        else {
            if(bMode === option.sat_answer_option_id){
                await fetch(`${backend}/api/satAnswerOptions/${option.sat_answer_option_id}`, {
                    method: "GET",
                    headers: { 'Authorization': 'Bearer ' + props.token },
                }).then((response) => {
                    if (response && response.status === 200) {
                        response.json().then((data) => {
                            fetch(`${backend}/api/satAnswerOptions/${option.sat_answer_option_id}`, {
                                method: "PUT",
                                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token},
                                body: JSON.stringify({
                                    question_id: data.question_id,
                                    text: newOptionText,
                                    is_correct: check,
                                    option_id: option.sat_answer_option_id
                                })
                            }).then((response) => {
                                if (response && response.status === 200) {
                                    response.json().then((data) => {
                                        props.setCheckUpdate(true);
                                    });
                                } else {
                                    console.log(response.status)
                                }
                            });
                        });
                    } else {
                        console.log(response.status)
                    }
                });
            }
            setBMode(-1)
            setNewOptionText('')
            setCheck(false);
        }
    }

    const handleAddNewOption = async() => {
        await fetch(`${backend}/api/satAnswerOptions`, {
            method: "POST",
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token},
            body: JSON.stringify({
                question_id: props.question.sat_question_id,
                option_text: ' ',
                is_correct: false,
            })
        }).then((response) => {
            if (response && response.status === 201) {
                response.json().then((data) => {
                    props.setCheckUpdate(true);
                });
            } else {
                console.log(response.status)
            }
        });
    }

    const DeleteOption = async(option) => {
        const response = await fetch(`${backend}/api/satAnswerOptions/${option.sat_answer_option_id}`, {
            method: "DELETE",
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token },
        });
        if (response && response.status === 200) {
            const data = await response.json();
            props.setCheckUpdate(true);
        }
    }


    return(
        <div>
            <div className="SATtest-task-question">
                <div className="SATtest-task-question-content">
                    <div>{props.question &&
                            <MDXEditor
                                key={editorKey}
                                contentEditableClassName="SATtest-mdxEditor"
                                markdown={props.question.question_text}
                                plugins={[headingsPlugin(), listsPlugin(), quotePlugin(), thematicBreakPlugin(), imagePlugin(),
                                ]}
                            />
                        }
                    </div>
                    <img src={props.question.image} alt="" />
                    {props.question?.answerOptions?.sort((a, b) => a.sat_answer_option_id - b.sat_answer_option_id).map((option, index) => (
                        <div className="SATtest-option-wrapper" key={index}>
                            <div className={props.crossout.includes(index) ? "SATtest-option option-crossed" : (chooseQ === option.sat_answer_option_id || props.option === option.sat_answer_option_id ? "SATtest-option active" : "SATtest-option")} onClick={() => handleAnswer(option.sat_answer_option_id)}>
                                <div className="SATtest-letter">{alphabet[index]}</div>
                                {
                                    bMode === option.sat_answer_option_id ?
                                    <textarea
                                    value={newOptionText}
                                    onChange={(e) => setNewOptionText(e.target.value)}
                                    required
                                    />
                                    :
                                    <span>{option.text}</span>
                                }
                                {props.crossout.includes(index) && <div className="option-crossline"></div>}
                            </div>
                            {sessionStorage.getItem("role") !== 'student' && bMode === option.sat_answer_option_id && <> <input className="test-checkbox" type="checkbox" checked={check} onClick={(e) => setCheck(!check)}/></>}
                            {sessionStorage.getItem("role") !== 'student' && <button onClick={() => handleUpdateOption(option)}>{bMode === option.sat_answer_option_id ? 'Save' : 'Edit'}</button>}
                            {sessionStorage.getItem("role") !== 'student' && <button onClick={() => DeleteOption(option)}>Delete</button>}
                            {sessionStorage.getItem("role") === 'student' && <div className="SATtest-option-cross" onClick={() => handleCross(index)}>{props.crossout.includes(index) ? "Undo" : "Cross out"}</div>}
                        </div>
                    ))}
                    {sessionStorage.getItem("role") !== 'student' && <button className="SATtest-button" onClick={handleAddNewOption}>Add New Option</button>}
                </div>
            </div>
        </div>
    )
}