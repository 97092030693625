import "./tab.css"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { format } from 'date-fns';
import { backend } from "../../../App";

export default function Tab(props) {

    const [checkB, setCheckB] = useState(props.isDone);
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState('');
    const [popOpen, setPopOpen] = useState(false);
    const [timeOpen, setTimeOpen] = useState('');
    const [openTime, setOpenTime] = useState('');
    const [deadline, setDeadline] = useState('');

    const handleClick = async() => {
        if(sessionStorage.getItem('role') === 'student'){
            if(Date.now() >= Date.parse(openTime)){
                localStorage.removeItem('Answers')
                navigate(`/quiz`, {state:{quiz_id: props.resource}})
            }
        } else {
            localStorage.removeItem('Answers')
            navigate(`/quiz`, {state:{quiz_id: props.resource}})
        }
        
    }

    const handleUpdate = async() => {
        await fetch(`${backend}/api/tests/${props.resource}`, {
            method: "PUT",
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token },
            body: JSON.stringify({
                time_open: timeOpen
            })
        }).then((response) => {
            if (response && response.status === 200) {
                    props.setRefresh(true);
            }
        });

        const response = await fetch(`${backend}/api/tests/${props.resource}`, {
            method: "GET",
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token },
        });

        if (response && response.status === 200) {
          const data = await response.json();
            console.log(data)
          await fetch(`${backend}/api/calendars`, {
              method: "POST",
              headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token },
              body: JSON.stringify({
                  title: data.name,
                  description: 'quiz',
                  group_id: sessionStorage.getItem('group_id'),
                  startTime: data.time_open,
                  endTime: deadline
              })
          }).then((response) => {
              if (response && response.status === 201) {
              } else {
                  console.log("Error: " + response.status);
              }
          });
        }

        props.setRefresh(true);
        setTimeOpen('');
        setDeadline('');
        setPopOpen(false);
    }

    useEffect(() => {
        (
            async() => {
                const response = await fetch(`${backend}/api/tests/${props.resource}`, {
                    method: "GET",
                    headers: { 'Authorization': 'Bearer ' + props.token }
                });
                if (response.ok) {
                    const data = await response.json();
                    const date = new Date(data.time_open);
                    setOpenTime(data.time_open);
                    setStartDate(format(date, 'dd MMMM HH:mm aa'));
                  }
            }
        )()
      }, []);

    const handleMarkAsDone = async() => {
        if(checkB === true){
            const contentResponse = await fetch(`${backend}/api/contents/${props.content_id}/unmark-as-done`, {
                method: "PATCH",
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token },
            });
            if (contentResponse && contentResponse.status === 200) {
            }
        }
        if(checkB === false){
            const contentResponse = await fetch(`${backend}/api/contents/${props.content_id}/mark-as-done`, {
                method: "PATCH",
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.token },
            });
            if (contentResponse && contentResponse.status === 200) {
            }
        }
        setCheckB(!checkB);
    }

    const popup = (
        <div className="tab-popUp">
            <div className="tab-popUp-close" onClick={() => setPopOpen(false)}></div>
            <div className="tab-popUp-block">
                <div className="tab-popUp-ramka">
                    <div>
                        <label>Time Open</label>
                        <input
                            type="datetime-local"
                            value={timeOpen}
                            onChange={(e) => setTimeOpen(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Time Close</label>
                        <input
                            type="datetime-local"
                            value={deadline}
                            onChange={(e) => setDeadline(e.target.value)}
                            required
                        />
                    </div>
                    <button onClick={handleUpdate}>Update</button>
                </div>
            </div>
        </div>
    )

    return(
        <>
            {popOpen && popup}
            <div className="tabStyleWrapper">
                <div className="tabStyle">
                    {props.mode === 'text' && 
                    <>
                        <a onClick={handleClick} className={(sessionStorage.getItem('role') === 'student' && Date.now() < Date.parse(openTime)) ? "tabStyle-quiz-close" : ''}><img src="assets/icons/quiz.png" alt=""/> {props.number} {props.title}</a>
                        <div className="tabStyle-date">Open: {startDate} {sessionStorage.getItem('role') !== 'student' && <button className="tab-date-button" onClick={() => setPopOpen(true)}>Edit</button>}</div>
                    </>
                    }
                    {props.mode === 'file' && <a href={props.resource} target="_blank" download={props.title}><img src="assets/icons/docs.png" alt=""/> {props.number} {props.title}</a>}
                    {props.mode === 'video' && 
                    <>
                        <div><img src="assets/icons/video.png" alt=""/> {props.number} {props.title}</div>
                        <video width="360" height="260" controls> 
                            <source src={props.resource} type="video/mp4" /> 
                        </video>
                    </>
                    }
                </div>
                <button className={checkB ? "tab-mark tab-mark-active" : "tab-mark"} onClick={handleMarkAsDone}>Mark as done</button>
            </div>
        </>
    )
}