import "./quizMaker.css"
import { useState, useRef, useEffect } from "react"
import { backend } from "../../../App";

export default function QuizMaker(props) {
    let token = JSON.parse(JSON.stringify(sessionStorage.getItem("token")));

    const [quizReady, setQuizReady] = useState(false);
    const [groupId, setGroupId] = useState();
    const [curTest, setCurTest] = useState({
        test_id: -1
    });
    const [curQuestions, setCurQuestions] = useState([-1]);
    const [uploadFile, setUploadFile] = useState(null);
    const [uploadFile2, setUploadFile2] = useState(null);
    const fileUploadRef = useRef();
    const fileUploadRef2 = useRef();
    const [deadline, setDeadline] = useState('');
    const [questionUp, setQuestionUp] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState('');
    const [groups, setGroups] = useState([]);

    const handleAvatarUpload = async () => {
        fileUploadRef.current?.click();
    }

    const uploadProfileImage = async () => {
        if (fileUploadRef.current && fileUploadRef.current.files) {
            setUploadFile(fileUploadRef.current.files[0]);
        }
    }

    useEffect(() => {
        (async () => {
            if (uploadFile && fileUploadRef.current && fileUploadRef.current.files) {
                setQuestion({ ...question, image: uploadFile });
            }
        })()
    }, [uploadFile])

    const handleExpImg = async () => {
        fileUploadRef2.current?.click();
    }

    const uploadExpImg = async () => {
        if (fileUploadRef2.current && fileUploadRef2.current.files) {
            setUploadFile(fileUploadRef2.current.files[0]);
        }
    }

    useEffect(() => {
        (
            async() => {
                const response = await fetch(`${backend}/api/groups`, {
                    headers: { 'Authorization': 'Bearer ' + token }
                });
                if (response.ok) {
                    const data = await response.json();
                    setGroups(data);
                    if (data.length > 0) {
                      setSelectedGroup(data[0].group_id); // Set default selected group
                    }
                  }
            }
        )()
      }, [backend]);

    useEffect(() => {
        (async () => {
            if (uploadFile2 && fileUploadRef2.current && fileUploadRef2.current.files) {
                setQuestion({ ...question, explanation_image: uploadFile2 });
            }
        })()
    }, [uploadFile2])

    const [quiz, setQuiz] = useState({
        name: '',
        duration: '',
        maxAttempts: '',
        timeOpen: '',
        questions: [],
    });

    const [question, setQuestion] = useState({
        text: '',
        hint: '',
        image: '',
        explanation_image: '',
        explanation: '',
        options: [{ text: '', isCorrect: false }],
    });

    const createQuiz = async () => {
        await fetch(`${backend}/api/tests`, {
            method: "POST",
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
            body: JSON.stringify({
                group_id: selectedGroup,
                name: quiz.name,
                time_open: quiz.timeOpen,
                duration_minutes: quiz.duration,
                max_attempts: quiz.maxAttempts,
                subject: props.subject
            })
        }).then((response) => {
            if (response && response.status === 200) {
                response.json().then((data) => {
                    setCurTest({test_id: data.test_id});
                    setQuizReady(true)
                });
            }else{
            }
        });
    }

    useEffect(() => {
        (
            async() => {
                if(quizReady === true) {
                    await fetch(`${backend}/api/calendars`, {
                        method: "POST",
                        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
                        body: JSON.stringify({
                            title: quiz.name,
                            description: '',
                            group_id: selectedGroup,
                            startTime: quiz.timeOpen,
                            endTime: deadline
                        })
                    }).then((response) => {
                        if (response && response.status === 201) {
                        } else {
                            console.log("Error: " + response.status);
                        }
                    });
                    props.setResource(curTest.test_id)
                }
            }
        )()
    },[quizReady])

    const handleQuizChange = (e) => {
        const { name, value } = e.target;
        if(name === 'duration' || name === 'maxAttempts'){
            if(value > 0){
                setQuiz({ ...quiz, [name]: value });
            }
        }
        else {
            setQuiz({ ...quiz, [name]: value });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        createQuiz();
    };


    return (
        <div className="quizMaker">
            <h1>Create a Quiz</h1>
            <form onSubmit={handleSubmit} className="quiz-maker-form">
                <div>
                    <label>Group</label>
                    <select 
                        value={selectedGroup}
                        onChange={(e) => setSelectedGroup(e.target.value)}
                        className="lessonMaker-group"
                        >
                        {groups[0] && groups.map(group => (
                        <option key={group.group_id} value={group.group_id}>
                            {group.name}
                        </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Quiz Name</label>
                    <input
                        name="name"
                        value={quiz.name}
                        onChange={handleQuizChange}
                        required
                    />
                </div>
                <div>
                    <label>Duration (minutes)</label>
                    <input
                        name="duration"
                        type="number"
                        value={quiz.duration}
                        onChange={handleQuizChange}
                        required
                    />
                </div>
                <div>
                    <label>Max Attempts</label>
                    <input
                        name="maxAttempts"
                        type="number"
                        value={quiz.maxAttempts}
                        onChange={handleQuizChange}
                        required
                    />
                </div>
                <div>
                    <label>Time Open</label>
                    <input
                        name="timeOpen"
                        type="datetime-local"
                        value={quiz.timeOpen}
                        onChange={handleQuizChange}
                        required
                    />
                </div>
                <div>
                    <label>Time End</label>
                    <input
                        name="timeEnd"
                        type="datetime-local"
                        value={deadline}
                        onChange={(e) => setDeadline(e.target.value)}
                        required
                    />
                </div>
                <button onClick={handleSubmit} id="createQuizButton">Create Quiz</button>
            </form>
        </div>
    );
}
